import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-redirect-pop-up-emp',
  templateUrl: './redirect-pop-up-emp.component.html',
  styleUrls: ['./redirect-pop-up-emp.component.scss']
})
export class RedirectPopUpEmpComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<RedirectPopUpEmpComponent>) { }

  ngOnInit() {
  }

  loginRedirect(){
    window.location.href="https://transitionshub.dam.aprimo.com/dam/";
  }

  public dismiss() {
    this.dialogRef.close();
  }

}
