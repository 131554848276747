import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { apiService } from '../core/services/api.service';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { HttpClient } from '@angular/common/http';
import { ModalComponent } from '../shared/modal/modal.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  userForm: FormGroup;
  isDisabled: boolean = true;
  constructor(private apiService: apiService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private http: HttpClient) { 

      this.userForm = this.formBuilder.group({
        firstName: ["",Validators.required],
        lastName: ["",Validators.required],
        email: ["",[Validators.required,Validators.email]],
        company: ["",Validators.required],
        country: ["",Validators.required],
        position: ["",Validators.required],
        loginId:["",Validators.required],
        acceptTC:["",Validators.required],
        whoIsYourContact:["",Validators.required],
        optin:[""]
      });

    }

    get firstName(): AbstractControl {
      return this.userForm.get("firstName");
    }

    get lastName(): AbstractControl {
      return this.userForm.get("lastName");
    }

    get email(): AbstractControl {
      return this.userForm.get("email");
    }

   
    get company(): AbstractControl {
      return this.userForm.get("company");
    }

    get country(): AbstractControl {
      return this.userForm.get("country");
    }

    get position(): AbstractControl {
      return this.userForm.get("position");
    }

    get loginId(): AbstractControl {
      return this.userForm.get("loginId");
    }

    get acceptTC(): AbstractControl {
      return this.userForm.get("acceptTC");
    }

    get whoIsYourContact(): AbstractControl {
      return this.userForm.get("whoIsYourContact");
    }

    get optin(): AbstractControl {
      return this.userForm.get("optin");
    }


    

    @ViewChild(ModalComponent) child;



    

  ngOnInit() {

    // this.child.showLoading = true;

  }

  checkValue(event: any){
    if (event.checked == true)
      this.isDisabled = false;
    else
      this.isDisabled = true;
  }

  registration(){
    if (!this.userForm.valid){
      return 0;
    }
    
    this.child.showLoading = true;
     this.apiService.registration(this.userForm)
     .subscribe(
      resp => {
       console.log(resp);
       this.child.showLoading = false;
       this.child.popupMessage = 'Registration complete. Shortly you will receive an email to create a password and complete your registration.'
       this.child.redirectUrl = ['/login'];
       this.child.showMessage = true;
        // if (this.region == "fr")
        //   this.openSnackBar("Votre demande est soumise avec succès","Sauvé!");
        // else
        //   this.openSnackBar("Your request is submitted successfully","Saved!");          
      },
      err => {
        this.child.showLoading = false;
        this.child.popupMessage = 'Error occured'
        this.child.showMessage = true;
        console.log("Error occured");
      });
   

  }

  openLoginDialog(): void {
    this.dialog.open(TermsConditionsComponent, {
      width: '1200px',
      maxWidth: '89.3vw',
      maxHeight: '85vh',
    });
  }



}
