import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-pop-up',
  templateUrl: './redirect-pop-up.component.html',
  styleUrls: ['./redirect-pop-up.component.scss']
})
export class RedirectPopUpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
