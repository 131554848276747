import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginNewComponent } from './login-new/login-new.component';
import { LoginComponent } from './login/login.component';
import { RegistrationNewComponent } from './registration-new/registration-new.component';
import { RegistrationComponent } from './registration/registration.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
  path: "registration",
  component: RegistrationNewComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "login-new",
    component: LoginNewComponent,
  },
  {
    path: "registration-new",
    component: RegistrationNewComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
