import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Http,Headers, RequestOptions } from "@angular/http";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })

  export class apiService {
    
    
    constructor(private httpClient: HttpClient,
      
        ) {
        }

        
        
      registration(user){
        var id = "Mark1234";
        const body = {
          "currencyCode": 7,
          "laborRateCurrencyCode": 7,
          "userType": 1,
          "prefix": "Mr",
          "firstName": user.value.firstName,
          "lastName": user.value.lastName,
          "loginId": user.value.loginId,
          "languageId": 1,
          "autoSave": 0,
          "dateFormat": 1,
          "timeFormat": 1,
          "numberFormatId": 1,
          "timezoneId": 43,
          "htmlEmail": true,
          "company": user.value.company,
          "analyzeAllDomains": 0,
          "isPinReset": 0,
          "passwordExpires": 1,
          "adHocUser": false,
          "activeFlag": 0,
          "applicationUser": false,
          "portalUser": true,
          "reviewUser": false,
          "isOutOfOffice": false,
          "outOfOfficeStatus": 0,
          "localeId": 1,
          "clientLoggingLevel": 0,
          "groups": [
              315
          ],
          "email": user.value.email,
          "phone": "",
          "paperSize": 1,
          "preferredLandingPage": 5,
          "extendedAttributes": [
              {
                  "eaId": 1,
                  "skipDCTSave": false,
                  "eaValue": user.value.acceptTC == true ? "Yes" : "No",
                  "attributeName": "Accepted Terms and Conditions"
              },
              {
                  "eaId": 101,
                  "skipDCTSave": false,
                  "eaValue": user.value.position,
                  "attributeName": "Position Description"
              },
              {
                "eaId": 2,
                "skipDCTSave": false,
                "eaValue": user.value.country,
                "attributeName": "Country"
              },
              {
                "eaId": 201,
                "skipDCTSave": false,
                "eaValue": user.value.whoIsYourContact,
                "attributeName": "Sales Contact"
              },
              {
                "eaId": 301,
                "skipDCTSave": false,
                "eaValue": user.value.optin == true ? "Yes" : "No",
                "attributeName": "Marketing Communications Opt-in"
              }
          ]
      };
      
      
        return this.httpClient.post(environment.webAPI,body);
      }
     

  }