import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import {  MatButtonModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule, MatProgressSpinnerModule, MatSpinner } from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ModalComponent } from './shared/modal/modal.component';
import { RedirectPopUpComponent } from './redirect-pop-up/redirect-pop-up.component';
import { RedirectPopUpEmpComponent } from './redirect-pop-up-emp/redirect-pop-up-emp.component';
import { ApprovalComponent } from './approval/approval.component';
import { LoginNewComponent } from './login-new/login-new.component';
import { RegistrationNewComponent } from './registration-new/registration-new.component';

@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    LoginComponent,
    TermsConditionsComponent,
    ModalComponent,
    RedirectPopUpComponent,
    RedirectPopUpEmpComponent,
    ApprovalComponent,
    LoginNewComponent,
    RegistrationNewComponent
  ],
  imports: [
    HttpClientModule,
    HttpModule,
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule

  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents:[TermsConditionsComponent,RedirectPopUpComponent,RedirectPopUpEmpComponent]
})
export class AppModule { }
