import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { apiService } from '../core/services/api.service';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { HttpClient } from '@angular/common/http';
import { ModalComponent } from '../shared/modal/modal.component';


@Component({
  selector: 'app-registration-new',
  templateUrl: './registration-new.component.html',
  styleUrls: ['./registration-new.component.scss']
})
export class RegistrationNewComponent implements OnInit {

  userForm: FormGroup;
  isDisabled: boolean = true;
  lnameIsReq: boolean = false;
  fnameIsReq: boolean = false;
  emailIsReq: boolean = false;
  companyIsReq: boolean = false;
  positionIsReq: boolean = false;
  loginidIsReq: boolean = false;

  constructor(private apiService: apiService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private http: HttpClient) {

    this.userForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      company: ["", Validators.required],
      country: [""],
      position: ["", Validators.required],
      loginId: ["", Validators.required],
      acceptTC: ["", Validators.required],
      whoIsYourContact: [""],
      optin: [""]
    });

  }

  get firstName(): AbstractControl {
    return this.userForm.get("firstName");
  }

  get lastName(): AbstractControl {
    return this.userForm.get("lastName");
  }

  get email(): AbstractControl {
    return this.userForm.get("email");
  }


  get company(): AbstractControl {
    return this.userForm.get("company");
  }

  get country(): AbstractControl {
    return this.userForm.get("country");
  }

  get position(): AbstractControl {
    return this.userForm.get("position");
  }

  get loginId(): AbstractControl {
    return this.userForm.get("loginId");
  }

  get acceptTC(): AbstractControl {
    return this.userForm.get("acceptTC");
  }

  get whoIsYourContact(): AbstractControl {
    return this.userForm.get("whoIsYourContact");
  }

  get optin(): AbstractControl {
    return this.userForm.get("optin");
  }




  @ViewChild(ModalComponent) child;





  ngOnInit() {

    // this.child.showLoading = true;

  }

  checkValue(event: any) {
    if (event.checked == true)
      this.isDisabled = false;
    else
      this.isDisabled = true;
  }

  registration() {


    this.onChangeFname(this.userForm.value.firstName);
    this.onChangeLname(this.userForm.value.lastName);
    this.onChangeEmail(this.userForm.value.email);
    this.onChangeCompany(this.userForm.value.company);
    this.onChangePosition(this.userForm.value.position);
    this.onChangeLoginID(this.userForm.value.loginId);
 

    console.log(this.userForm.value);

    if (!this.userForm.valid) {
      return 0;
    }

    this.child.showLoading = true;
    this.apiService.registration(this.userForm)
      .subscribe(
        resp => {
          console.log(resp);
          this.child.showLoading = false;
          this.child.popupMessage = 'Registration complete. Shortly you will receive an email to create a password and complete your registration.'
          this.child.redirectUrl = ['/login'];
          this.child.showMessage = true;
          // if (this.region == "fr")
          //   this.openSnackBar("Votre demande est soumise avec succès","Sauvé!");
          // else
          //   this.openSnackBar("Your request is submitted successfully","Saved!");          
        },
        err => {
          this.child.showLoading = false;
          this.child.popupMessage = 'Your login ID already exists.'
          this.child.showMessage = true;
          console.log("Error occured");
        });


  }

  openLoginDialog(): void {
    this.dialog.open(TermsConditionsComponent, {
      width: '1200px',
      maxWidth: '89.3vw',
      maxHeight: '85vh',
    });
  }


  setClasses(ventStr: string) {
    let classes = {
      constantClass: true,
      'conditional-class': ventStr == "test"
    }

    return classes;
  }

  onChangeFname(value: string): void {
    console.log(value);
    if (value == "")
      this.fnameIsReq = true;
    else
      this.fnameIsReq = false;
  }

  onChangeLname(value: string): void {
    console.log(value);
    if (value == "")
      this.lnameIsReq = true;
    else
      this.lnameIsReq = false;
  }

  onChangeEmail(value: string): void {
    console.log(value);
    if (value == "")
      this.emailIsReq = true;
    else {
      this.emailIsReq = false;
      if (this.validateEmail(value))
        this.emailIsReq = false;
      else
        this.emailIsReq = true;
    }

  }

  onChangeCompany(value: string): void {
    console.log(value);
    if (value == "")
      this.companyIsReq = true;
    else
      this.companyIsReq = false;
  }

  onChangePosition(value: string): void {
    console.log(value);
    if (value == "")
      this.positionIsReq = true;
    else
      this.positionIsReq = false;
  }

  onChangeLoginID(value: string): void {
    console.log(value);
    if (value == "")
      this.loginidIsReq = true;
    else
      this.loginidIsReq = false;
  }


  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

}
