import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  popupMessage: string;
  showLoading: boolean = false;
  showMessage: boolean = false;
  redirectUrl: string = ''; 
  constructor(private route: ActivatedRoute,
    private router: Router,) { }

  ngOnInit() {
  }

  onOk() {
    this.showMessage = false;
    if (this.redirectUrl.length > 0)
      // window.location.href = 'https://transitionshub.aprimo.com/aprimo/AprimoRegistration.aspx?PageID=13167&Domain=1&DomainEncrypt=0';
       this.router.navigateByUrl(this.redirectUrl);
  }

}
