import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { RedirectPopUpEmpComponent } from '../redirect-pop-up-emp/redirect-pop-up-emp.component';
import { RedirectPopUpComponent } from '../redirect-pop-up/redirect-pop-up.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(public dialog: MatDialog,) { }

  ngOnInit() {
  }

    loginRedirect(){

      const cacheLoginIn = localStorage.getItem("transitions-dam-Login")
      
      if (cacheLoginIn == null){
        this.dialog.open(RedirectPopUpComponent, {
          width: '800px',
          maxWidth: '85.3vw',
          maxHeight: '65vh',
        });

        localStorage.setItem("transitions-dam-Login", "yes");
  
        setTimeout(() => {
          window.location.href="https://transitionshub.aprimo.com/Aprimo/";
        }, 3000);
      }else
      window.location.href="https://transitionshub.aprimo.com/Aprimo/";

      

   
  }

  loginSSO(){

   
    this.dialog.open(RedirectPopUpEmpComponent, {
      width: '800px',
      maxWidth: '85.3vw',
      maxHeight: '75vh',
    });


    // const cacheLoginIn = localStorage.getItem("transitions-dam-Login-sso")
      
    //   if (cacheLoginIn == null){
    //     this.dialog.open(RedirectPopUpEmpComponent, {
    //       width: '700px',
    //       maxWidth: '69.3vw',
    //       maxHeight: '55vh',
    //     });

    //     localStorage.setItem("transitions-dam-Login-sso", "yes");
  
    //     setTimeout(() => {
    //       window.location.href="https://transitionshub.dam.aprimo.com/dam/";
    //     }, 3000);
    //   }else
    //   window.location.href="https://transitionshub.dam.aprimo.com/dam/";

   
  }

}
